import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {  IBlockState } from "../types";
import { ApiService, url } from "../utils/endpoints";
import { toast } from "sonner";
import { getSimplifiedError } from "../utils";
import { setStorageValue } from "../utils/localStorage";

const block: IBlockState = {
  loading: false,
  success: false,
  error: {
    status: null,
    message: "",
  },
  data: null,
};

const initialState = {
  bankState: { ...block },
  accountState: { ...block },
};

const startLoading = (state: any, initialStateKey: string, payload: any) => {
  if (state.hasOwnProperty(initialStateKey)) {
    Object.keys(payload).forEach((key: string) => {
      if (state[initialStateKey].hasOwnProperty(key)) {
        state[initialStateKey][key] = payload[key];
      }
    });
  }
};

const handleFulfilled = (
  state: any,
  initialStateKey: string,
  { payload }: { payload: any }
) => {
  if (state.hasOwnProperty(initialStateKey)) {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = true;
    state[initialStateKey].data = {
      ...payload.data,
    };
  }
};

const handleRejected = (
  state: any,
  initialStateKey: string,
  action: PayloadAction<any>
) => {
  state[initialStateKey].loading = false;
  state[initialStateKey].success = false;
  state[initialStateKey].error = {
    status: action.payload?.status || null,
    message: action.payload?.message || "",
  };
};

export const onFetchBanks = createAsyncThunk(
  "account/setup",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.put(url.account_setup, payload, {
        headers: {
          Authorization: `Bearer ${user?.user?.registerState?.data?.auth?.token}`,
        },
      });
      toast.success("Account setup successful");
      // const data = res.data.data.map((bank: any) => {
      //   return {
      //     ...bank,
      //     label: bank.name,
      //     value: bank.name,
      //   };
      // });
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onAddBankAccount = createAsyncThunk(
  "account/setup/bank",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.post(url.banks, payload, {
        headers: {
          Authorization: `Bearer ${user?.user?.token}`,
        },
      });
      toast.success("Bank account added successfully");
      setStorageValue({ key: "bmj_stage", value: ""})
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const bankSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      Object.assign(state, initialState);
    },
    resetBlockBank(state, action: PayloadAction<{ blockType: any }>) {
      return {
        ...state,
        [action.payload.blockType]: {
          ...initialState[action.payload.blockType],
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onFetchBanks.pending, (state, action) =>
        startLoading(state, "bankState", { loading: true, success: false })
      )
      .addCase(onFetchBanks.fulfilled, (state, action) =>
        handleFulfilled(state, "bankState", action)
      )
      .addCase(onFetchBanks.rejected, (state, action) =>
        handleRejected(state, "bankState", action)
      )
      .addCase(onAddBankAccount.pending, (state, action) =>
        startLoading(state, "accountState", { loading: true, success: false })
      )
      .addCase(onAddBankAccount.fulfilled, (state, action) =>
        handleFulfilled(state, "accountState", action)
      )
      .addCase(onAddBankAccount.rejected, (state, action) =>
        handleRejected(state, "accountState", action)
      );
  },
});

export const bankSelector = (state: any) => state.banks;
export const { clearState, resetBlockBank } = bankSlice.actions;

export default bankSlice.reducer;
