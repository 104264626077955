import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handleFulfilled, initialStateObj } from "../../utils/common";
import { ApiService, url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
import { handleRejected, startLoading } from "../Authentication/authSlice";

const initialState = {
  dashboardStats: { ...initialStateObj },
  dashboardEarnings: { ...initialStateObj },
};

export const getOverviewStats = createAsyncThunk(
  "overviewStats",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.get(url.dashboardStats, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const getOverviewEarnings = createAsyncThunk(
  "overviewEarnings",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      let endpoint = `${url.dashboardEarnings}`;

      // Add date range parameters if provided
      const params = new URLSearchParams();
      if (payload.from) params.append("from", payload.from);
      if (payload.to) params.append("to", payload.to);

      // Append parameters to the URL if any exist
      if (params.toString()) {
        endpoint += `?${params.toString()}`;
      }

      const { data } = await ApiService.get(endpoint, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      console.log("from redux", data);
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const overviewSlice = createSlice({
  name: "overviewsPage",
  initialState,
  reducers: {
    clearOverviewState: (state) => {
      Object.assign(state, initialState);
    },
    resetOverviewBlock(state, action: PayloadAction<{ blockType: any }>) {
      return action.payload.blockType === null
        ? { ...state, loading: false, success: false }
        : {
            ...state,
            [action.payload.blockType]: {
              ...initialState[action.payload.blockType],
            },
          };
    },
    resetFlag(state, action: PayloadAction<{ blockType: string }>) {
      const { blockType } = action.payload;
      const initialStateForKey = initialState[blockType];
      const b = {
        ...state,
        [blockType]: {
          ...state[blockType],
          loading: initialStateForKey.loading,
          success: initialStateForKey.success,
        },
      };
      console.log("the reset flag new data", b);
      return b;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOverviewStats.pending, (state, action) => {
        startLoading(state, "dashboardStats", {
          loading: true,
          success: false,
        });
      })
      .addCase(getOverviewStats.fulfilled, (state, action) => {
        handleFulfilled(state, "dashboardStats", action);
      })
      .addCase(getOverviewStats.rejected, (state, action) => {
        handleRejected(state, "dashboardStats", action);
      })
      .addCase(getOverviewEarnings.pending, (state, action) => {
        startLoading(state, "dashboardEarnings", {
          loading: true,
          success: false,
        });
      })
      .addCase(getOverviewEarnings.fulfilled, (state, action) => {
        handleFulfilled(state, "dashboardEarnings", action);
      })
      .addCase(getOverviewEarnings.rejected, (state, action) => {
        handleRejected(state, "dashboardEarnings", action);
      });
  },
});

export const overviewPageSelector = (state: any) => state.overviewsPage;
export const { clearOverviewState, resetOverviewBlock, resetFlag } =
  overviewSlice.actions;

export default overviewSlice.reducer;
