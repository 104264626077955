import { lazy } from "react";
import ProtectedRoute, { PublicRoute } from "./utils/protectedRoute";
import CreateWishlist from "./containers/Wishlist/CreateWishlist";

const Login = lazy(() => import("./containers/Authentication/Login"));
const PasswordReset = lazy(
  () => import("./containers/Authentication/Password-reset")
);
const Verify = lazy(() => import("./containers/Authentication/Verify"));
const NewPassword = lazy(
  () => import("./containers/Authentication/NewPassword")
);

const Register = lazy(() => import("./containers/Authentication/Register"));
const AccountSetup = lazy(
  () => import("./containers/Authentication/AccountSetup")
);
const Dashboard = lazy(() => import("./containers/Dashboard"));
const Overview = lazy(() => import("./containers/Overview"));
const MyPage = lazy(() => import("./containers/MyPage"));
const CreatorPage = lazy(() => import("./containers/MyPage/CreatorPage"));
const Wishlist = lazy(() => import("./containers/Wishlist"));
const WishlistList = lazy(() => import("./containers/Wishlist/WishlistList"));
const Memberships = lazy(() => import("./containers/Memberships"));
const CreateMembership = lazy(
  () => import("./containers/Memberships/CreateMembership")
);
const ListMemberships = lazy(
  () => import("./containers/Memberships/ListMemberships")
);
const Settings = lazy(() => import("./containers/Settings"));

const Supporters = lazy(() => import("./containers/Supporters"));
const SupportersList = lazy(
  () => import("./containers/Supporters/SupportersList")
);
const SingleSupporter = lazy(
  () => import("./containers/Supporters/SingleSupporter")
);

const RouteList = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    redirectTo: "login",
  },
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  { path: "/password-reset", element: <PasswordReset /> },
  { path: "/verify", element: <Verify /> },
  { path: "/new-password", element: <NewPassword /> },
  { path: "/register", element: <Register /> },
  { path: "/account-setup", element: <AccountSetup /> },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    redirectTo: "overview",
    isProtected: true,
    children: [
      { path: "overview", element: <Overview />, isProtected: true },
      { path: "settings", element: <Settings />, isProtected: true },
      {
        path: "supporters",
        element: <Supporters />,
        isProtected: true,
        children: [
          { path: "", element: <SupportersList />, isProtected: true },
          { path: ":id", element: <SingleSupporter />, isProtected: true },
        ],
      },
      {
        path: "my-page",
        element: <MyPage />,
        isProtected: true,

        children: [
          { path: "", element: <CreatorPage />, isProtected: true },
          {
            path: "wishlist",
            element: <Wishlist />,
            isProtected: true,
            children: [
              { path: "", element: <WishlistList />, isProtected: true },
              {
                path: "create",
                element: <CreateWishlist />,
                isProtected: true,
              },
            ],
          },
          {
            path: "membership",
            element: <Memberships />,
            isProtected: true,
            children: [
              { path: "", element: <ListMemberships />, isProtected: true },
              {
                path: "create",
                element: <CreateMembership />,
                isProtected: true,
              },
              {
                path: ":id/edit",
                element: <CreateMembership />,
                isProtected: true,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default RouteList;
