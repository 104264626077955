import { ChangeEvent, useState } from "react";

import { cva } from "class-variance-authority";
import React from "react";

interface IAppInput {
  type: string;
  placeholder: string;
  name: string;
  label: string;
  errors?: any;
  register?: any;
  maxLength?: number;
  noLabel?: boolean;
  height?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | any;
}

const input = cva(
  [
    "font-gt-w-regular placeholder:text-neutral-100 border-[1px] outline:none rounded-full pl-4  focus:outline-none focus:ring-2 focus:ring-primary-600",
  ],
  {
    variants: {
      intent: {
        primary: ["border-lightGray", "focus:border-smoky_black"],
        danger: ["border-red-700", "focus:border-red-700"],
      },
      size: {
        large: ['py-4'],
        medium: ['py-[14px]']
      },
      defaultVariants: {
        size: 'large'
      }
    },
  }
);

const AppInput = React.forwardRef(
  (
    {
      type,
      placeholder,
      name,
      label,
      errors,
      register,
      onChange,
      maxLength,
      noLabel,
      height,
      value,
      disabled
    }: IAppInput,
    ref
  ) => {
    const { ref: formRef, ...rest } = register(name);

    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
      setVisible(!visible);
    };

    return (
      <div className="flex flex-col w-full relative">
        {!noLabel && (
          <label
            htmlFor={name}
            className="text-sm mb-1 text-black-100 font-gt-w-medium"
          >
            {label}
          </label>
        )}

        <input
          type={type === "password" && visible ? "text" : type}
          placeholder={placeholder}
          name={name}
          id={name}
          value={value}
          ref={ref || formRef}
          {...rest}
          min={1}
          max={maxLength}
          maxLength={maxLength}
          onChange={(e) => {
            rest.onChange(e);
            if (onChange) onChange(e);
          }}
          disabled={disabled}
          autoComplete="off"
          aria-autocomplete="none"
          className={`${input({
            intent: errors[name] ? "danger" : "primary",
            size: 'large',
          })}`}
          style={{height: height}}
        />
        {type === "password" && (
          <span
            className="absolute top-[44px] right-4 cursor-pointer uppercase text-xs font-gt-w-regular text-neutral-100"
            onClick={toggleVisibility}
          >
            {!visible ? "Show" : "Hide"}
          </span>
        )}
        {errors[name] && (
          <span className="text-xs text-red-700 font-gt-w-regular pt-1">
            {errors[name]?.message}
          </span>
        )}
      </div>
    );
  }
);

export default AppInput;
