import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RouteList from "./RouteList";
import { FullLogo } from "./assets/icons";

const renderLoader = () => (
  <div className="w-full h-screen flex items-center justify-center">
    <FullLogo />
  </div>
);

const AppRoutes = () => {
  const generateRoutes = (routes: any) => {
    return routes.map((route: any) => {
      const { path, element, children, redirectTo } = route;

      return (
        <Route key={path} path={path} element={element}>
          {redirectTo && <Route index element={<Navigate to={redirectTo} />} />}
          {children && generateRoutes(children)}
        </Route>
      );
    });
  };

  return (
    <Router>
      <Suspense fallback={renderLoader()}>
        <Routes>{generateRoutes(RouteList)}</Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
