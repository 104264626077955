import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { CameraIcon, CloseIcon } from "../../assets/icons";
import { IFilePreview } from "../../types";
import clsx from "clsx";
import Loader from "../Loader";

interface IFileUploader {
  getFiles: (files: any) => void;
  isEditing?: boolean;
  images?: any;
  thumbnail?: boolean;
  label?: string;
  innerContent?: () => JSX.Element;
  onUpload?: MouseEventHandler<HTMLButtonElement>;
  progress?: number;
  loading?: boolean;
}

const Fileuploader = ({
  getFiles,
  isEditing,
  images,
  label,
  thumbnail,
  innerContent,
  onUpload,
  progress,
  loading,
}: IFileUploader) => {
  const imageRef = useRef(null);
  const [files, setFiles] = useState<File[]>([]);
  const [preview, setPreview] = useState<IFilePreview[]>([]);
  const [imageUrls, setImageUrls] = useState([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(() => [...acceptedFiles]);
  }, []);

  useEffect(() => {
    if (isEditing === true && !imageUrls?.length) {
      setImageUrls([images?.url]);
      console.log({ images });
    }
  }, [isEditing, images]);

  useEffect(() => {
    getFiles(files);
    generatePreview(files);
  }, [files]);

  const generatePreview = (files: File[] | undefined) => {
    const newFiles =
      files?.map((file) => {
        return {
          file: URL.createObjectURL(file),
          name: file.name,
        };
      }) || [];
    setPreview(newFiles);
  };

  const removeImage = (selected: string, name: string) => {
    const newPreview = preview?.filter((file) => file.file !== selected);
    const newFiles = files?.filter((file) => file.name !== name);
    setPreview(newPreview);
    setImageUrls(imageUrls.filter((url) => url !== selected));
    // getFiles(newFiles)
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/avif": [],
    },
    multiple: false,
  });

  return (
    <section className="w-full flex flex-col">
      {label ? (
        <h6 className="text-sm mb-3 font-gt-w-medium">{label}</h6>
      ) : null}
      <div className="relative">
        <div
          {...getRootProps()}
          className={clsx(
            "w-full relative h-full bg-neutral-50 border-neutral-400  border-dashed border-main cursor-pointer",
            {
              "rounded-full border-[1px] box-border": thumbnail,
              "rounded-lg border-[2px]": !thumbnail,
            }
          )}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Upload membership image</p>
          ) : preview?.length || imageUrls?.length ? (
            <div className="flex gap-4">
              {preview?.map(({ file, name }) => {
                return (
                  <div
                    className={clsx("w-full  relative", {
                      "h-14 rounded-full": thumbnail,
                      "h-56 ": !thumbnail,
                    })}
                    key={file}
                  >
                    <img
                      src={file}
                      alt=""
                      className={clsx("w-full h-full object-cover", {
                        "rounded-full": thumbnail,
                        "rounded-lg": !thumbnail,
                      })}
                      ref={imageRef}
                    />
                    <span
                      onClick={() => removeImage(file, name)}
                      className={clsx(
                        "flex items-center justify-center bg-red-600  rounded-full  cursor-pointer absolute",
                        {
                          "w-6 h-6 top-2 right-2": !thumbnail,
                          "w-4 h-4 top-0 right-0": thumbnail,
                        }
                      )}
                    >
                      <CloseIcon
                        width={thumbnail ? "16" : "24"}
                        height={thumbnail ? "16" : "24"}
                      />
                    </span>
                  </div>
                );
              })}
              {imageUrls?.map((item) => {
                return (
                  <div
                    className="w-14 h-14 object-cover rounded-full relative"
                    key={item}
                  >
                    <img
                      src={item}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                      ref={imageRef}
                    />
                    <span
                      onClick={() => removeImage(item, item)}
                      className="flex items-center justify-center bg-red-600  rounded-full w-4 h-4 cursor-pointer absolute top-0 right-0"
                    >
                      <CloseIcon className="w-4 h-4 text-white" />
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className={clsx(
                "flex flex-col justify-center items-center  mx-auto",
                {
                  "h-14 w-14 rounded-full": thumbnail,
                  "h-56 w-full rounded-lg": !thumbnail,
                }
              )}
            >
              <CameraIcon
                width={thumbnail ? 24 : 48}
                height={thumbnail ? 24 : 48}
              />
              {!thumbnail ? (
                <>
                  <h6 className="text-sm font-gt-w-medium mt-3">
                    Upload membership image
                  </h6>
                  <p className="text-sm font-gt-w-regular text-center text-neutral-600">
                    Drag & drop or{" "}
                    <span className="text-purple-p300">Choose file</span> to
                    upload
                  </p>
                </>
              ) : null}
            </div>
          )}
        </div>
        {preview?.length ? (
          <div className="">
            <button
              className={clsx(
                "absolute top-4  w-fit font-gt-w-medium bg-white rounded-md text-black py-2 px-4",
                {
                  "right-[-200px]": thumbnail,
                  "left-1/2 top-1/2 translate-y-1/2 transform -translate-x-1/2":
                    !thumbnail,
                }
              )}
              disabled={loading}
              onClick={onUpload}
            >
              {loading ? (
                <Loader color="#121212" />
              ) : thumbnail ? (
                "Upload as profile photo"
              ) : (
                "Click here to upload"
              )}
            </button>
          </div>
        ) : null}
        {progress ? (
          <div className="bg-primary-500 w-full h-3 flex items-center absolute bottom-1 rounded-full">
            <div
              className="transition-all duration-500 ease-out"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Fileuploader;
