const local = window.localStorage;

export const getStorageValue = (key: string) => {
  const b = local.getItem(key);

  if (b === "" || b === undefined || b == null) {
    return null;
  } else {
    return JSON.parse(b); // Parse only if b is not empty, undefined, or null
  }
};

export const setStorageValue = ({
  key,
  value,
}: {
  key: string;
  value: string | boolean;
}) => {
  return local.setItem(key, JSON.stringify(value));
};

export const removeStorageValue = (key: string) => {
  return local.removeItem(key);
};
