import { Toaster } from "sonner";
import AppRoutes from "./Routes";
import { Cloudinary } from "@cloudinary/url-gen";

export const cld = new Cloudinary({ cloud: { cloudName: "groomlyhq" } });

function App() {
  return (
    <>
      <Toaster position="top-center" richColors />
      <AppRoutes />
    </>
  );
}

export default App;
