// import { store } from "../store/store";

// const ProtectedRoute = ({ children }: { children: any }) => {
//   const token = store.getState()?.user?.token;
//   const username = store.getState().user?.user?.username;
//   if (token === undefined || token === null || !token.length && username === undefined || username === null || !username?.length) {
//     return window.location.replace("/login")
//   }
//   return children;
// };

// export const PublicRoute = ({ children }: { children: any }) => {
//   const token = store.getState()?.user?.token;
//   const username = store.getState().user?.user?.username;
//   if (token === undefined || token === null || !token.length && username === undefined || username === null || !username?.length) {
//     return children
//   }
//   return  window.location.replace('/dashboard/overview')
// };

// export default ProtectedRoute;

import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const ProtectedRoute = ({ children }: { children: any }) => {
  const user = useSelector((state: RootState) => state.user);
  const token = user?.token;
  const username = user?.user?.username || user?.user?.client?.username;

  if (!token || !username) {
    window.location.replace("/login");
    return null; // Ensure nothing is rendered before the redirect
  }

  return children;
};

export const PublicRoute = ({ children }: { children: any }) => {
  const user = useSelector((state: RootState) => state.user);
  const token = user?.token;
  const username = user?.user?.username || user?.user?.client?.username;

  if (!token || !username) {
    return children;
  }

  window.location.replace("/dashboard/overview");
  return null; // Ensure nothing is rendered before the redirect
};

export default ProtectedRoute;
