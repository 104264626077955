import styles from "./loader.module.css";

interface ILoader {
  color?: string;
}
const Loader = ({ color }: ILoader) => {
  let cstyle = {
    borderColor: `${color} ${color} ${color} transparent`,
  };

  return <div style={cstyle} className={styles.loader}></div>;
};

export default Loader;
