import { ChangeEvent } from "react";

import { cva } from "class-variance-authority";
import React from "react";

interface IAppInput {
  placeholder: string;
  name: string;
  label?: string;
  errors?: any;
  register?: any;
  value?: string;
  height: string;
  noLabel?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const input = cva(
  [
    "font-gt-w-regular placeholder:text-neutral-100 border-[1px] outline:none rounded-xl pl-4 py-4 focus:outline-none focus:ring-2 focus:ring-primary-600",
  ],
  {
    variants: {
      intent: {
        primary: ["border-lightGray", "focus:border-smoky_black"],
        danger: ["border-red-700", "focus:border-red-700"],
      },
    },
  }
);

const TextArea = React.forwardRef(
  (
    {
      placeholder,
      name,
      label,
      errors,
      register,
      onChange,
      value,
      height,
      noLabel,
    }: IAppInput,
    ref
  ) => {
    const { ref: formRef, ...rest } = register(name);

    return (
      <div className="flex flex-col w-full relative">
        {!noLabel && (
          <label
            htmlFor={name}
            className="text-sm mb-1 text-black-100 font-gt-w-medium"
          >
            {label}
          </label>
        )}

        <textarea
          placeholder={placeholder}
          name={name}
          id={name}
          value={value}
          ref={ref || formRef}
          {...rest}
          onChange={(e) => {
            rest.onChange(e);
            if (onChange) onChange(e);
          }}
          className={`${input({
            intent: errors[name] ? "danger" : "primary",
          })} ${height}`}
          style={{resize: 'none'}}
        />
        {errors[name] && (
          <span className="text-xs text-red-700 font-gt-w-regular pt-1">
            {errors[name]?.message}
          </span>
        )}
      </div>
    );
  }
);

export default TextArea;
