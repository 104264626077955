import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import CreateForm from "../../components/CreateForm";
import TextArea from "../../components/TextArea";
import Fileuploader from "../../components/Fileuploader";
import AppInput from "../../components/AppInput";
import AppSelect from "../../components/AppSelect";
import { wishlistCategory } from "../../utils/mockData";
import { useState } from "react";
import { ICreateWishlist, IOption } from "../../types";
import AppCalendar from "../../components/Calendar";

const CreateWishlist = () => {
  const navigate = useNavigate();
  const location = useLocation()?.pathname.replace(/\/\w+$/, "");
  const [data, setData] = useState<ICreateWishlist>({});

  const {
    // handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const goBack = () => {
    navigate(location);
  };

  const onHandleCategoryChange = (selected: IOption) => {
    setData({
      ...data,
      category: selected,
    });
  };

  const onHandleSubmit = () => {
    alert("working");
  };

  return (
    <CreateForm
      title="My Wishlists 🛍️"
      footerAction={{
        cancelTitle: "Cancel",
        cancelFunction: () => goBack(),
        okTitle: "Create membership",
        okFunction: () => onHandleSubmit(),
      }}
    >
      <form className="w-full pt-8">
        <div className="w-full px-8">
          <div className="mb-8">
            <AppInput
              label="What's the name of your wishlist?"
              placeholder="Cuppy dat music"
              name="name"
              type="text"
              register={register}
              errors={errors}
            />
          </div>

          <div className="w-full mb-8">
            <Fileuploader
              label="Wishlist cover photo"
              isEditing={false}
              getFiles={() => {}}
              images={[]}
            />
          </div>
          <div className="mb-8">
            <TextArea
              placeholder="Describe this wishlist to your supporters"
              label="Write something compelling about your wishlist"
              name="description"
              register={register}
              errors={errors}
              height="h-[149px]"
            />
          </div>
          <div className="flex gap-6">
            <div className="flex-1">
              <AppSelect
                placeholder="Select a category"
                label="What category is your wishlist?"
                options={wishlistCategory}
                onHandleSelect={onHandleCategoryChange}
                selected={
                  data?.category || { id: "", name: "", value: "", label: "" }
                }
              />
            </div>
            <div className="flex-1">
              <AppCalendar label={"Set Due Date"} />
            </div>
          </div>
        </div>
      </form>
    </CreateForm>
  );
};

export default CreateWishlist;
