import { useState } from "react";
import dayjs from "dayjs";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CalendarIcon } from "../../assets/icons";
import clsx from "clsx";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const AppCalendar = ({ label }) => {
  const [value, onChange] = useState<Value>(new Date());
  const [selected, setSelected] = useState(null);
  const [visible, setVisible] = useState(null);


  const handleDateChange = (date: ValuePiece) => {
    const formattedDate1 = date
      ? dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ")
      : null;
    const formattedDate2 = date ? dayjs(date).format("ddd D MMM") : null;
    const formattedDate3 = date ? dayjs(date).format("D/MM/YYYY") : null;
    setSelected(formattedDate3);
    onChange(date);
    setVisible(false)
  };

  return (
    <div className="flex flex-col w-full relative">
      <span className="text-sm mb-3 font-gt-w-medium">{label}</span>
      <div className="flex flex-col w-full border-[1px] border-gray-300 rounded-full px-5 py-4 cursor-pointer">
        <div
          className="flex justify-between items-center w-full"
          onClick={() => setVisible(!visible)}
        >
          <span
            className={clsx("text-sm font-gt-w-regular", {
              text: selected,
              "text-neutral-600": !selected,
            })}
          >
            {selected ?? "Select a Date"}
          </span>
          <CalendarIcon width="16px" height="16px" />
        </div>
        {visible && (
          <Calendar
            onChange={handleDateChange}
            value={value}
            className="absolute top-24 w-full"
          />
        )}
      </div>
    </div>
  );
};

export default AppCalendar;
