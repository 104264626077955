import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { DownArrow } from "../../assets/icons";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function AppSelect({
  label,
  options,
  onHandleSelect,
  selected,
  placeholder
}: {
  label: string;
  options: { id: string; name: string; value: string; label: string }[];
  selected: { id?: string; name: string; value: string; label: string };
  onHandleSelect?: any;
  placeholder: string
}) {
  return (
    <Listbox value={selected} onChange={(option) => onHandleSelect(option)}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-gt-w-medium leading-6 text-gray-900">
            {label}
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-full bg-white py-4 pl-5 pr-40 text-left text-gray-900  ring-1 ring-inset ring-neutral-300 focus:outline-none focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6">
              <span className="block truncate font-gt-w-regular text-sm capitalize">
                {selected?.name ? selected?.name : placeholder }
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <DownArrow />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options?.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "bg-primary-100 text-primary-600 font-gt-w-medium"
                          : "text-gray-900",
                        "relative cursor-default select-none py-3 pl-4 pr-4 font-gt-w-regular text-sm capitalize"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 left-0 flex items-center pl-1.5"
                            )}
                          ></span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
