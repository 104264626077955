import AppButton from "../AppButton";

const CreateForm = ({
  title,
  footerAction,
  children,
}: {
  title: string;
  footerAction: {
    cancelTitle: string;
    cancelFunction: Function;
    okTitle: string;
    isLoading?: boolean;
    okFunction: Function;
  };
  children: React.ReactNode;
}) => {
  return (
    <section className="w-full bg-white pb-8 mt-10 rounded-2xl">
      <header className="p-8 text-2xl font-gt-su-md border-b-[1px] border-[#F5F5F5]">
        {title}
      </header>
      <main className="pb-12">{children}</main>
      <footer className="flex justify-end px-8">
        <div className="flex items-center gap-4">
          <AppButton
            title={footerAction?.cancelTitle}
            onClick={() => footerAction?.cancelFunction()}
            size={"medium"}
            intent={"secondary"}
            disabled={footerAction?.isLoading}
          />
          <AppButton
            title={footerAction?.okTitle}
            intent="primary"
            onClick={() => footerAction?.okFunction()}
            size={"medium"}
            isLoading={footerAction?.isLoading}
          />
        </div>
      </footer>
    </section>
  );
};

export default CreateForm;
