import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handleFulfilled, initialStateObj } from "../../utils/common";
import { ApiService, url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
import { handleRejected, startLoading } from "../Authentication/authSlice";

const initialState = {
  ...initialStateObj,
  singleData: { ...initialStateObj },
};

export const getSupporters = createAsyncThunk(
  "suppoertersList",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.get(url.supporters, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const getSupporterById = createAsyncThunk(
  "singleSupporter",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.get(
        `${url.supporters}/${payload.id}/purchase`,
        {
          headers: { Authorization: `Bearer ${user?.user?.token}` },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const supportersSlice = createSlice({
  name: "supportersPage",
  initialState,
  reducers: {
    clearState: (state) => {
      Object.assign(state, initialState);
    },
    resetSupportersBlock(state, action: PayloadAction<{ blockType: any }>) {
      return action.payload.blockType === null
        ? { ...state, loading: false, success: false }
        : {
            ...state,
            [action.payload.blockType]: {
              ...initialState[action.payload.blockType],
            },
          };
    },
    resetSupportersFlag(state, action: PayloadAction<{ blockType: string }>) {
      const { blockType } = action.payload;
      const initialStateForKey = initialState[blockType];
      if (blockType === null) {
        return {
          ...initialState,
          data: state.data, // Retain user data
        };
      }
      return {
        ...state,
        [blockType]: {
          ...state[blockType],
          loading: initialStateForKey.loading,
          success: initialStateForKey.success,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupporters.pending, (state, action) => {
        startLoading(state, null, { loading: true, success: false });
      })
      .addCase(getSupporters.fulfilled, (state, action) => {
        handleFulfilled(state, null, action);
      })
      .addCase(getSupporters.rejected, (state, action) => {
        handleRejected(state, null, action);
      })
      .addCase(getSupporterById.pending, (state, action) => {
        startLoading(state, "singleData", { loading: true, success: false });
      })
      .addCase(getSupporterById.fulfilled, (state, action) => {
        handleFulfilled(state, "singleData", action);
      })
      .addCase(getSupporterById.rejected, (state, action) => {
        handleRejected(state, "singleData", action);
      });
  },
});

export const supportersPageSelector = (state: any) => state.supportersPage;
export const { clearState, resetSupportersBlock, resetSupportersFlag } =
  supportersSlice.actions;

export default supportersSlice.reducer;
